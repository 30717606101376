import { render, staticRenderFns } from "./404-working.vue?vue&type=template&id=65cd772e&scoped=true&"
import script from "./404-working.vue?vue&type=script&lang=ts&"
export * from "./404-working.vue?vue&type=script&lang=ts&"
import style0 from "./404-working.vue?vue&type=style&index=0&id=65cd772e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65cd772e",
  null
  
)

export default component.exports